import { useMediaQuery } from "react-responsive";

const TabletScreen = () => {
  const isTablet = useMediaQuery({
    // maxDeviceWidth: 1024,
    // minDeviceWidth: 768,
    maxWidth: 1024,
    minWidth: 768,
  });
  return isTablet;
};

export default TabletScreen;
