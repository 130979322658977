import React from "react";
import HeaderNavigation from "../../components/AppHeaderNavigation";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/AppFooter";
import { Row, Col, Container } from "react-bootstrap";

const AboutScreen = ({ isMobile, isTabletOrMobile }) => {
  const isTablet = useMediaQuery({
    maxWidth: 768,
    minWidth: 768,
  });

  const isLargeScreen = useMediaQuery({
    minWidth: 1000
  });
  return (
    <Container fluid>
      <div id={!isMobile ? "about-page-section-1" : ""} >
        <HeaderNavigation isTabletOrMobile={isMobile} />
        <Container fluid={!isLargeScreen}>
          <div className="px-0 d-flex align-items-center mb-3 " style={{ paddingTop: isTabletOrMobile ? isMobile ? '4vh' : '8vh' : '20vh' }}>
            <Row className="px-0 mx-0 d-flex align-items-center">
              <Col lg={6} md={6} sm={12} >
                <p className={`${isMobile || isTablet ? 'h6' : 'h5'} font-weight-bold`} style={{ lineHeight: 1 }}>Our Mission </p>
                <p className={`${isMobile || isTablet ? 'h4' : 'h2'} font-weight-bold`} style={{ lineHeight: 1 }}
                >Close the <br /> Opportunity Gap <br /> in Tech</p>
                <p className={`${isMobile || isTablet ? 'h6' : 'h5'} font-weight-bold pt-4`}>Our Mission Is To Close The Opportunity Gap In Tech By Providing Everyone Equal Access To Quality
                  Tech Education And Employment Opportunities.</p>
              </Col>
            </Row>
          </div>
          <Col className="ml-3" style={{ border: '1px solid grey', width: '5rem', margin: isMobile ? '3rem 0' : '5rem 0' }} />
          <Row className="mx-0 d-flex align-items-center">
            <Col>
              <p className="h4 font-weight-bold" style={{ lineHeight: 1 }}>What We Do Everyday </p>
              <p>We work hard building a platform that connects tech talent with equal skills and career growth opportunities.</p>
            </Col>
            <Row className=" mx-0 d-flex align-items-center">
              <Col md={6} lg={3} xs={6} className="px-2 mb-4" >  <img src="assets/about/about-img1.png" alt="" className="about-img" /></Col>
              <Col md={6} lg={3} xs={6} className="px-2 mb-4" >  <img src="assets/about/about-img2.png" alt="" className="about-img" /></Col>
              <Col md={6} lg={3} xs={6} className="px-2 mb-4" >   <img src="assets/about/about-img3.png" alt="" className="about-img" /> </Col>
              <Col md={6} lg={3} xs={6} className="px-2 mb-4" >    <img src="assets/about/about-img4.png" alt="" className="about-img" /> </Col>
            </Row>
          </Row>
        </Container>
      </div>
      <div id={isLargeScreen ? "about-page-section-2" : ""} className="d-flex align-items-center" style={{ marginBottom: isTabletOrMobile ? '3rem' : '5rem', paddingTop: isTabletOrMobile ? '5rem' : '20vh', paddingBottom: isTabletOrMobile ? '5rem' : '25vh' }}>
        <Container fluid={!isLargeScreen}>
          <Row className="mx-0 d-flex align-items-center">
            {!isLargeScreen &&
              <Col >
                <img src="assets/about/vector2.png" alt="" width="100%" height="auto" />
              </Col>
            }
            <Col lg={{ span: 5, offset: 7 }} md={{ span: 6 }} sm={12} >
              <p className="h4 font-weight-bold pt-5" style={{ lineHeight: 1 }}
              >Our Belief</p>
              <p className="py-2">If we could get to a place of true equality, where what we do in life is determined not by geography, race, gender and social background but by our capabilities, our companies would collectively be more productive and our world, better.</p>
              <p className="h4 font-weight-bold mt-5" style={{ lineHeight: 1 }}>Building A Place</p>
              <p className="h4 font-weight-bold" style={{ lineHeight: 1 }}>For All Tech Talent </p>
              <p className="py-2">“Stack”, in tech, is a combination of technologies used to create applications. “Trek” means journey of improving skill and career. StackTrek is a place for tech talent to grow together.</p>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer isTabletOrMobile={isTabletOrMobile} />
    </Container>
  );
};

export default AboutScreen;
