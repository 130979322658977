import { useMediaQuery } from "react-responsive";

const MobileScreen = () => {
  const isMobile = useMediaQuery({
    // maxDeviceWidth: 767,
    maxWidth: 767,
  });
  return isMobile;
};

export default MobileScreen;
