import { useMediaQuery } from "react-responsive";

const TabletMobileScreen = () => {
  const isTabletOrMobile = useMediaQuery({
    maxWidth: 1024,
  });
  return isTabletOrMobile;
};

export default TabletMobileScreen;
