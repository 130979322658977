import { axios } from "../constant";

export async function saveEmail(data) {
  try {
    const res = await axios.post("https://accounts.stacktrek.com/api/stacktrek/email", data);
    return res;
  } catch (error) {
    return error.response;
  }
}
