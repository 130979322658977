import React from "react";
import HeaderNavigation from "../../components/AppHeaderNavigation";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/AppFooter";
import { Row, Col, Button, Container, Card } from "react-bootstrap";

const HomeScreen = ({ isMobile, isTabletOrMobile }) => {
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isTablet = useMediaQuery({
    maxWidth: 768,
    minWidth: 768,
  });
  return (
    <Container fluid>
      <div id={!isMobile ? "home-page" : ""}>
        <HeaderNavigation isTabletOrMobile={isMobile} />
        <div className="px-0 d-flex align-items-center my-5" style={{ minHeight: isTabletOrMobile ? '65vh' : '80vh' }}>
          <Container className="px-0"  >
            <Row className={`mx-0 ${isMobile || isTablet ? 'text-center' : 'text-left'}`}>
              <p className={
                !isMobile || (isMobile && !isPortrait)
                  ? "home-banner pl-4"
                  : "mobile-home-banner px-2"
              }
              >Connect Tech Talent  {(!(isMobile || isTablet) || (isMobile && !isPortrait)) && <br />}with Opportunities</p>
            </Row>
            {(isTablet || isMobile) &&
              <Row className="mx-0 justify-content-center">
                <img src="/assets/Home/home-bg-mobile.png" alt="" width={isTablet ? '70%' : "100%"} height="auto" />
              </Row>
            }
            <Row className={`mx-0 ${(isTablet || isMobile) ? 'mt-1' : "mt-2"}`}>
              <Col lg={3} sm={6}>
                <Card className="home-page-card" >
                  <Card.Title className="card-title">For Tech Talent</Card.Title>
                  <Card.Text className="card-content">
                    Sharpen your coding skills, connect with tech companies, compete in the nation’s largest programming league, or join a community of passionate developers.
                  </Card.Text>
                  <Button className="card-button" variant="secondary" href="https://accounts.stacktrek.com/sign-up/">Join Now</Button>
                </Card>
              </Col>
              <Col lg={3} sm={6}>
                <Card className="home-page-card">
                  <Card.Title className="card-title">For Companies</Card.Title>
                  <Card.Text className="card-content">
                    We are the Philippines’ leading platform in finding, training and hiring the best tech talent.
                  </Card.Text>
                  <Button className="card-button" variant="secondary" href="/companies">Get Early Access</Button>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer isTabletOrMobile={isTabletOrMobile} page="Home" />
      </div>
    </Container >
  );
};

export default HomeScreen;
