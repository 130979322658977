import React from "react";

import { Container } from "react-bootstrap";

const AppFooter = ({ isTabletOrMobile, page }) => (
  <Container fluid>
    {isTabletOrMobile ? (
      <div className="mt-5">
        {page !== "Home" &&
          <div className="d-flex pb-5 justify-content-center">
            <div>
              <img
                src="assets/footer-icon.png"
                alt=""
                className="footer-stacktrek-logo"
              />
            </div>
          </div>
        }
        <div
          className="d-flex justify-content-center"
          id="footer-bottom-info-1"
        >
          <div id="copyright-font" className="mb-3">
            Copyright © 2021 StackTrek
            <div className={"row justify-content-center"}>
              <a href="https://stacktrek.com/privacy.pdf">Privacy Policy</a>
              &nbsp;|&nbsp;
              <a href="https://stacktrek.com/eula.pdf">EULA</a>&nbsp;|&nbsp;
              <a href="https://stacktrek.com/terms.pdf">Terms</a>
            </div>
          </div>
        </div>
        {/* <div className="pb-2">
          <div className="d-flex justify-content-center">
            <Button
              variant="outline-dark"
              className="btn-circle"
              href="https://www.facebook.com/StackTrek"
            >
              <FontAwesomeIcon icon={faFacebookF} className="faIcon" />
            </Button>
            <Button
              variant="outline-dark"
              className="btn-circle"
              href="https://www.linkedin.com/company/stacktrek/"
            >
              <FontAwesomeIcon icon={faLinkedin} className="faIcon" />
            </Button>
            <Button
              variant="outline-dark"
              className="btn-circle"
              href="https://www.youtube.com/channel/UC3ZOzfpKg49nJ6Ki1J8vWOg"
            >
              <FontAwesomeIcon icon={faYoutube} className="faIcon" />
            </Button>
          </div>
        </div> */}
      </div>
    ) : (
      <>
        {page !== "Home" &&
          <div className="d-flex py-5 justify-content-center">
            <div>
              <img
                src="assets/footer-icon.png"
                alt=""
                className="footer-stacktrek-logo"
              />
            </div>

          </div>
        }
        <div className="d-flex py-3 justify-content-center footer-bg-2">
          <div className="d-flex align-items-center" id="copyright-font">
            Copyright © 2021 StackTrek &nbsp; | &nbsp;{" "}
            <a href="https://stacktrek.com/privacy.pdf">Privacy Policy</a>
            &nbsp;|&nbsp;
            <a href="https://stacktrek.com/eula.pdf">EULA</a>&nbsp;|&nbsp;
            <a href="https://stacktrek.com/terms.pdf">Terms</a>
          </div>
          {/* <div className="d-flex w-50 justify-content-end">
          <Button
            variant="outline-dark"
            className="btn-circle"
            href="https://www.facebook.com/StackTrek"
          >
            <FontAwesomeIcon icon={faFacebookF} className="faIcon" />
          </Button>
          <Button
            variant="outline-dark"
            className="btn-circle"
            href="https://www.linkedin.com/company/stacktrek/"
          >
            <FontAwesomeIcon icon={faLinkedin} className="faIcon" />
          </Button>
          <Button
            variant="outline-dark"
            className="btn-circle"
            href="https://www.youtube.com/channel/UC3ZOzfpKg49nJ6Ki1J8vWOg"
          >
            <FontAwesomeIcon icon={faYoutube} className="faIcon" />
          </Button>
        </div> */}
        </div>
      </>
    )}
  </Container>
);

export default AppFooter;
