import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";

import HeaderNavigation from "../../components/AppHeaderNavigation";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/AppFooter";

const TutorialScreen = ({ isMobile, isTablet, isTabletOrMobile }) => {
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const videoLinks = [
    {
      title: "How to Upload Materials",
      link: "https://www.youtube.com/embed/HJhvn9MJ4Tg",
    },
    {
      title: "Create Assignments",
      link: "https://www.youtube.com/embed/JWZ0Dyl-ygc",
    },
    {
      title: "Create Module and Sub Module",
      link: "https://www.youtube.com/embed/IZF6lk7u5wo",
    },
    { title: "Gradebook", link: "https://www.youtube.com/embed/Rr5-GaK_Kag" },
    {
      title: "Student's Dashboard",
      link: "https://www.youtube.com/embed/dqKdMvFxxKk",
    },
    {
      title: "Create Coding Quiz",
      link: "https://www.youtube.com/embed/U7EjCY9JjKg",
    },
    {
      title: "Pair Learning",
      link: "https://www.youtube.com/embed/eYAA8WKhY_Q",
    },
    { title: "Add Links", link: "https://www.youtube.com/embed/CmE5O9f6VAE" },
    {
      title: "Getting Started with StackEd (Student)",
      link: "https://www.youtube.com/embed/T5IRHfSldgs",
    },
    {
      title: "How to Copy Materials",
      link: "https://www.youtube.com/embed/xs23s7BP0IA",
    },
    { title: "Discussion", link: "https://www.youtube.com/embed/EaL7MnneTpA" },
    {
      title: "How to Add Class",
      link: "https://www.youtube.com/embed/1k6Q-GKNqvM",
    },
    {
      title: "How to Remove Students",
      link: "https://www.youtube.com/embed/98GxTqYT6bQ",
    },
    {
      title: "How to add Unregistered Users",
      link: "https://www.youtube.com/embed/eUFxyiGF-xk",
    },
    {
      title: "How to Set Number of Absences Allowed",
      link: "https://www.youtube.com/embed/qUYl6gi9J0I",
    },
    {
      title: "How to add an existing question",
      link: "https://www.youtube.com/embed/w1QavThAvDA",
    },
    {
      title: "How to Check Attendance",
      link: "https://www.youtube.com/embed/OYlo54n4ftI",
    },

    {
      title: "How to Create Quiz",
      link: "https://www.youtube.com/embed/_PeqFI5G24E",
    },
    {
      title: "How to add Registered Users",
      link: "https://www.youtube.com/embed/3ay9BIjtSwU",
    },
    {
      title: "How to Create Set",
      link: "https://www.youtube.com/embed/xKUsfDYnOsg",
    },
    {
      title: "Instructor's Dashboard",
      link: "https://www.youtube.com/embed/iECnPyOo6Mg",
    },
    {
      title: "How to Export Attendance",
      link: "https://www.youtube.com/embed/ngpmg-O-QvQ",
    },
    {
      title: "How to Setup Grading Weights",
      link: "https://www.youtube.com/embed/0sL1pqT2S74",
    },
    {
      title: "How to move materials",
      link: "https://www.youtube.com/embed/7zWPogfKApo",
    },
    {
      title: "Types of Courses",
      link: "https://www.youtube.com/embed/CLF5fprnInE",
    },
    {
      title: "How to Setup Grading Component",
      link: "https://www.youtube.com/embed/ilT-AEgduSc",
    },
    {
      title: "Share Course Key",
      link: "https://www.youtube.com/embed/pXEW8SqmPRY",
    },
    {
      title: "Side by Side Coding",
      link: "https://www.youtube.com/embed/BYPVj82HAUw",
    },
    {
      title: "Ways to delete questions",
      link: "https://www.youtube.com/embed/nb3xIhS1guY",
    },
    {
      title: "What are the use of Tags",
      link: "https://www.youtube.com/embed/2o7O0968iGg",
    },
    {
      title: "How to Sign-Up",
      link: "https://www.youtube.com/embed/0lBdmoNM4sw",
    },
    {
      title: "How to Create Course",
      link: "https://www.youtube.com/embed/SuKWGUWeOM0",
    },
  ];

  const chunkArray = (arr, num) => {
    let result = [];

    if (arr.length % num !== 0) {
      arr.push({ title: "", link: "" });
    }

    for (let i = 0; i < arr.length; i += num) {
      result.push(arr.slice(i, i + num));
    }
    return result;
  };

  let twoColumn = chunkArray(videoLinks, 2);
  let threeColumn = chunkArray(videoLinks, 3);

  return (
    <div>
      <Container fluid>
        <div id="tutorial-page">
          <HeaderNavigation isTabletOrMobile={isMobile} page="Tutorial" />
          <div
            className={
              isMobile && isPortrait
                ? "mx-4 text-left py-3"
                : !isTabletOrMobile
                  ? "text-center padding-tutorial"
                  : "text-center padding-tutorial mx-4"
            }
          >
            <p className={!isMobile ? "header-top mt-3" : "header-top-2"}>
              WELCOME TO YOUR FIRST DAY {!isTabletOrMobile && <br />} OF STACKED
            </p>
            <p className="header-top-content">
              StackED saves you time, keeps you organized and helps you{" "}
              {!isTabletOrMobile && <br />} communicate with your students. Get
              started today, {!isTabletOrMobile && <br />} with resources, tips
              and tricks from educators like you.
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center text-center padding-tutorial">
            <div className={!isTabletOrMobile ? "pb-5" : "pb-3"}>
              <p
                className={isMobile && isPortrait ? "body-top-3" : "body-top-2"}
              >
                THE BASICS
              </p>
              <p className="body-top-content">
                Video tutorials to get you started.
              </p>
            </div>
            <div className="d-flex justify-content-center text-left">
              <div
                className={
                  !isTabletOrMobile
                    ? "w-50 video-card"
                    : isMobile && isPortrait
                      ? "w-100 video-card mx-4"
                      : "w-75 video-card"
                }
              >
                <div className="tutorial-iframe">
                  <iframe
                    src="https://www.youtube.com/embed/rKKaTm3G_Ig"
                    frameBorder="0"
                    title="StackED Intro"
                    height="100%"
                    width="100%"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen="allowFullScreen"
                    mozallowfullscreen="mozallowfullscreen"
                    msallowfullscreen="msallowfullscreen"
                    oallowfullscreen="oallowfullscreen"
                    webkitallowfullscreen="webkitallowfullscreen"
                  />
                </div>
                <div className="p-3 d-flex align-items-center">
                  <div className="w-50">
                    <p className="body-top-content font-weight-bold mb-0">
                      StackED for Everyone
                    </p>
                    <p className="body-top-content-3 mb-0">LMS for Everyone</p>
                  </div>
                  <div className="w-50 text-right">
                    <Button variant="outline-light">
                      <span className="text-muted">
                        <FontAwesomeIcon icon={faShareAlt} /> Share
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="teachers-lounge padding-tutorial">
          <div
            className={
              !isTabletOrMobile ? "text-center pb-5" : "text-center pb-3"
            }
          >
            <p className={isMobile && isPortrait ? "body-top-3" : "body-top-2"}>
              TEACHERS' LOUNGE
            </p>
            <p className="body-top-content">
              Tips and tricks from teachers like you.
            </p>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div className="tutorial-videos">
              {!isTabletOrMobile &&
                threeColumn.map((data, index) => (
                  <CardDeck key={index}>
                    {data.map((item, index) =>
                      item.title !== "" ? (
                        <Card key={index} className="mb-3">
                          <div className="tutorial-iframe-2">
                            <iframe
                              src={item.link}
                              frameBorder="0"
                              title="StackED Videos"
                              height="100%"
                              width="100%"
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen="allowFullScreen"
                              mozallowfullscreen="mozallowfullscreen"
                              msallowfullscreen="msallowfullscreen"
                              oallowfullscreen="oallowfullscreen"
                              webkitallowfullscreen="webkitallowfullscreen"
                            />
                          </div>
                          <div className="p-3 d-flex align-items-center">
                            <div className="w-50">
                              <p className="body-top-content-2 font-weight-bold mb-0">
                                {item.title}
                              </p>
                            </div>
                            <div className="w-50 text-right">
                              <Button variant="outline-light">
                                <FontAwesomeIcon
                                  icon={faShareAlt}
                                  className="text-muted"
                                />
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <Card border="light"></Card>
                      )
                    )}
                  </CardDeck>
                ))}
              {((isTabletOrMobile && isPortrait) || (isMobile && !isPortrait))
                ? twoColumn.map((data, index) => (
                  <CardDeck key={index}>
                    {data.map((item, index) =>
                      item.title !== "" ? (
                        <Card key={index} className="mb-3">
                          <div className="tutorial-iframe-2">
                            <iframe
                              src={item.link}
                              frameBorder="0"
                              title="StackED Videos"
                              height="100%"
                              width="100%"
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen="allowFullScreen"
                              mozallowfullscreen="mozallowfullscreen"
                              msallowfullscreen="msallowfullscreen"
                              oallowfullscreen="oallowfullscreen"
                              webkitallowfullscreen="webkitallowfullscreen"
                            />
                          </div>
                          <div className="p-3 d-flex align-items-center">
                            <div className="w-50">
                              <p className="body-top-content-2 font-weight-bold mb-0">
                                {item.title}
                              </p>
                            </div>
                            <div className="w-50 text-right">
                              <Button variant="outline-light">
                                <FontAwesomeIcon
                                  icon={faShareAlt}
                                  className="text-muted"
                                />
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <Card border="light"></Card>
                      )
                    )}
                  </CardDeck>
                ))
                : threeColumn.map((data, index) => (
                  <CardDeck key={index}>
                    {data.map((item, index) =>
                      item.title !== "" ? (
                        <Card key={index} className="mb-3">
                          <div className="tutorial-iframe-2">
                            <iframe
                              src={item.link}
                              frameBorder="0"
                              title="StackED Videos"
                              height="100%"
                              width="100%"
                              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen="allowFullScreen"
                              mozallowfullscreen="mozallowfullscreen"
                              msallowfullscreen="msallowfullscreen"
                              oallowfullscreen="oallowfullscreen"
                              webkitallowfullscreen="webkitallowfullscreen"
                            />
                          </div>
                          <div className="p-3 d-flex align-items-center">
                            <div className="w-50">
                              <p className="body-top-content-2 font-weight-bold mb-0">
                                {item.title}
                              </p>
                            </div>
                            <div className="w-50 text-right">
                              <Button variant="outline-light">
                                <FontAwesomeIcon
                                  icon={faShareAlt}
                                  className="text-muted"
                                />
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ) : (
                        <Card border="light"></Card>
                      )
                    )}
                  </CardDeck>
                ))}
            </div>
          </div>
        </div>
        <div>
          <Footer
            page="Home"
            isMobile={isMobile}
            isTablet={isTablet}
            isSayingCenter={true}
          />
        </div>
      </Container>
    </div>
  );
};

export default TutorialScreen;
