import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import HomeScreen from "./Screens/Home";
import AboutScreen from "./Screens/About";
import CompaniesScreen from "./Screens/ForCompanies"
import MobileScreen from "./Others/MobileScreen";
import TabletScreen from "./Others/TabletScreen";
import TabletMobileScreen from "./Others/TabletMobileScreen";
import TutorialScreen from "./Screens/Tutorial";

function App() {
  return (
    <Router>
      <Route exact path="/">
        <HomeScreen
          isMobile={MobileScreen()}
          isTablet={TabletScreen()}
          isTabletOrMobile={TabletMobileScreen()}
        />
      </Route>
      <Route exact path="/companies">
        <CompaniesScreen
          isMobile={MobileScreen()}
          isTablet={TabletScreen()}
          isTabletOrMobile={TabletMobileScreen()}
        />
      </Route>
      <Route exact path="/about">
        <AboutScreen
          isMobile={MobileScreen()}
          isTablet={TabletScreen()}
          isTabletOrMobile={TabletMobileScreen()}
        />
      </Route>
      <Route exact path="/tutorial">
        <TutorialScreen
          isMobile={MobileScreen()}
          isTablet={TabletScreen()}
          isTabletOrMobile={TabletMobileScreen()}
        />
      </Route>
    </Router>
  );
}

export default App;
