import React, { useState } from "react";
import HeaderNavigation from "../../components/AppHeaderNavigation";
import { useMediaQuery } from "react-responsive";
import Footer from "../../components/AppFooter";
import { Row, Col, Button, Container, Form, Toast, Alert } from "react-bootstrap";
import Joi from "joi";
import { saveEmail } from "../../api/job_posting_waitlist";

const HomeScreen = ({ isMobile, isTabletOrMobile }) => {
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  const isExtraLargeScreen = useMediaQuery({
    minWidth: 1200,
  });
  const isLargeScreen = useMediaQuery({
    maxWidth: 1199,
    minWidth: 994
  });

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
  })

  const [isInvalid, setIsInvalid] = useState({
    email: false,
    name: false
  });

  const [isLoading, setIsLoading] = useState(false)
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    variant: "success"
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
    setIsInvalid(false);
  }
  const handleOnSubmit = async (event) => {

    const validateName = Joi.object().keys({
      name: Joi.string().trim().required(),
    });

    const validateEmail = Joi.object().keys({
      email: Joi.string().trim().required(),
    });

    const { error, value: trimmedName } = validateName.validate({ name: formValues.name });
    const { error: emailError, value: trimmedEmail } = validateEmail.validate({ email: formValues.email });
    event.preventDefault();
    event.stopPropagation();

    if (error || emailError) {
      setIsInvalid({
        name: error,
        email: emailError
      })
    } else {
      try {
        setIsLoading(true)
        const res = await saveEmail({ email: trimmedEmail.email, name: trimmedName.name });
        if (!res) return setIsLoading(false)
        if (res.status === 200) {
          setIsLoading(false);
          setFormValues({
            name: '',
            email: ''
          })
          setAlert({
            show: true,
            message: "Successfully joined the waitlist",
            variant: "success"
          });
        } else if (res.status === 422) {
          setAlert({
            show: true,
            message: "Email already exist!",
            variant: "danger"
          });
        } else {
          setAlert({
            show: true,
            message: "Failed! Please try again",
            variant: "danger"
          });
        }
      } finally {
        setIsLoading(false);
      }

    }
  };
  const isLargeScreenUp = isLargeScreen || isExtraLargeScreen
  return (
    <>
      <Container fluid style={{ minHeight: `calc(100vh - 190px)` }}>
        <HeaderNavigation isTabletOrMobile={isMobile} />
        <div id="companies">
          <div className="p-0 m-0 d-flex align-items-center" >
            <Container fluid={!isExtraLargeScreen} className={`px-0 pt-5 mt-4 ${!isExtraLargeScreen && 'px-2'}`} >
              <p className={
                !isMobile || (isMobile && !isPortrait)
                  ? "h3 text-white text-center "
                  : "h4 text-white text-center"
              }
              >Coming Soon</p>
              <p className="h5 text-white text-center mb-5"
              >Join the Early Access Waitlist For Free Job Posting Credits</p>
              <div className="about-img mt-5 py-3 bg-white" style={{ borderRadius: isExtraLargeScreen || isLargeScreen ? 45 : 20, padding: '0 2rem', marginBottom: isLargeScreenUp ? '-30px' : '-80px' }}>
                <Form onSubmit={handleOnSubmit}>
                  <Row className="d-flex align-items-center justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={5} xl={5} className={`px-0 ${!isLargeScreenUp ? 'mt-2' : 'pr-2'}`}>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder="Name"
                        className="fields"
                        value={formValues.name}
                        onChange={handleOnChange}
                        isInvalid={isInvalid.name}
                        required
                      />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5} className={`px-0 ${!isLargeScreenUp ? 'mt-2' : 'pr-2'}`} >
                      <Form.Control.Feedback type="invalid" tooltip>
                        This field is required
                      </Form.Control.Feedback>
                      <Form.Control
                        name="email"
                        type="text"
                        placeholder="Email Address"
                        className="fields"
                        value={formValues.email}
                        onChange={handleOnChange}
                        isInvalid={isInvalid.email}
                        required
                      />
                    </Col>
                    <Col lg={2} md={12} sm={12} xs={12} className={`px-0 ${!isLargeScreenUp && 'mt-2'}`} >
                      <Button type="submit" className="card-button" variant="secondary" style={{ width: '100%' }} disabled={isLoading}>JOIN WAITLIST</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Container>
          </div>
        </div>
        <div style={{
          marginTop: isLargeScreenUp ? '5rem' : '9rem'
        }}>
          <p className="h5 text-center px-2"
          >Trusted by Some of the Biggest Tech Companies</p>
          <Container>
            {isMobile ?
              <div class="row d-flex align-items-center mt-5">
                <Col sm={6} className="px-2 mb-4 d-flex justify-content-center" >  <img src="assets/companies/company1.png" alt="" width="150px" style={{ backgroundBlendMode: 'multiply' }} /></Col>
                <Col sm={6} className="px-2 mb-4 d-flex justify-content-center" >  <img src="assets/companies/company2.png" alt="" width="80px" /></Col>
                <Col sm={6} className="px-2 mb-4 d-flex justify-content-center" >   <img src="assets/companies/company3.png" alt="" width="150px" /> </Col>
                <Col sm={6} className="px-2 mb-4 d-flex justify-content-center" >    <img src="assets/companies/company4.png" alt="" width="250px" /> </Col>
              </div> :
              <img src="assets/companies/company.png" alt="" width="100%" height="100%" />
            }


          </Container>
        </div>
      </Container >
      <Footer isTabletOrMobile={isTabletOrMobile} />

      <Toast
        autohide
        show={alert.show}
        style={{
          position: "fixed",
          top: 20,
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
        }}
        onClose={() => setAlert({ show: false, message: "", variant: "" })}
      >
        <Alert
          variant={alert.variant} style={{ marginBottom: 0 }} >
          {alert.message}
        </Alert>
      </Toast>
    </>
  );
};

export default HomeScreen;
