import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";

const AppHeaderNavigation = ({ isTabletOrMobile, page }) => {

  return (
    isTabletOrMobile ?
      <Navbar expand="xl" className={page === "Tutorial" && "navbar-dark"}>
        <Navbar.Brand href="/">
          <Image
            src={page === "Tutorial" ? "assets/StackTrek Logo.png" : "assets/stacktrek-talent.png"}
            className="header-stacktrek-logo"
            alt=""
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="pt-3">
          <Nav>
            <Nav.Link href="/about">
              <span className={page !== "Tutorial" && "home-nav-link "} >
                ABOUT US
              </span>
            </Nav.Link>
            <Nav.Link href="https://accounts.stacktrek.com/">
              <span className={page !== "Tutorial" && "home-nav-link "} >
                LOGIN
              </span>
            </Nav.Link>
            <Nav.Link href="https://accounts.stacktrek.com/sign-up/">
              <span className={page !== "Tutorial" && "home-nav-link "} >
                JOIN NOW
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar> :
      <Navbar className="justify-content-center navbar-space ptvh-2">
        <Container>
          <Navbar.Brand href="/">
            <Image
              src={page === "Tutorial" ? "assets/StackTrek Logo.png" : "assets/stacktrek-talent.png"}
              className="header-stacktrek-logo"
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Collapse className="justify-content-end" >
            <Nav>
              <Nav.Link href="/about">
                <span className={page !== "Tutorial" && "home-nav-link "} >
                  ABOUT US
                </span>
              </Nav.Link>
              <Nav.Link href="https://accounts.stacktrek.com/" >
                <span className={page !== "Tutorial" && "home-nav-link "} >
                  LOGIN
                </span>
              </Nav.Link>
            </Nav>
            <Button id="home-button" variant={page !== "Tutorial" ? "outline-primary" : "outline-light"} size="lg" href="https://accounts.stacktrek.com/sign-up/">
              JOIN NOW
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  )
};

export default AppHeaderNavigation;